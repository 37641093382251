<template>
  <div class="guide-cnt3">
    <h6 class="h6">发票说明</h6>
    <div class="cnt">
      <p class="p">1、开票金额：</p>
      <p class="p">微微定仅开具您实际支付金额的发票，不含优惠券等其他金额；</p>
      <p class="p">2、发票信息：</p>
      <p class="p">微微定提供正规机打增值税普通发票，开票项目默认为设计费。公司开票需正确填写单位全称、纳税人识别号、公司注册地址、
电话、开户行及账号；</p>
      <p class="p">3、发票寄送：</p>
      <p class="p">需要发票请您联系在线客服，提供开票信息，订单完成后，我们会尽快安排为您寄出。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-cnt3',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt3 {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 14px;color:#333;margin-bottom:10px;
      &:nth-child(2n+2) {
        margin-bottom:30px;
      }
    }
  }
}
</style>
